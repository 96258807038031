/* Global */
select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

[type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[multiple],
textarea,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Color */
.fuel-text-teal-bright {
  color: #0fb7ae;
}
.fuel-text-teal {
  color: #0c7075;
}
.fuel-text-teal-dark {
  color: #0c3435;
}
/* Background */
.fuel-bg-teal-lightest {
  background-color: #f5fafa;
}
.fuel-bg-teal-light {
  background-color: #dbf4f3;
}
.fuel-bg-teal {
  background-color: #0c7075;
}
.hover\:fuel-bg-teal:hover {
  background-color: #0c7075;
}
.fuel-bg-teal-dark {
  background-color: #0c3435;
}
.hover\:fuel-bg-teal-dark:hover {
  background-color: #0c3435;
}
.fuel-bg-teal-darkest {
  background-color: #002021;
}
.hover\:fuel-bg-teal-darkest:hover {
  background-color: #002021;
}

/* Custom Overrides */
.navbar-item--active {
  background-color: #0c7075;
  color: white !important;
}
.navbar-item--active:hover {
  background-color: #0c7075;
}

/* Min-Height */
.min-h-12 {
  min-height: 3rem;
}

@media (min-width: 110em) {
  html {
    font-size: 1.05em;
  }
}
@media (min-width: 120em) {
  html {
    font-size: 1.1em;
  }
}
@media (min-width: 130em) {
  html {
    font-size: 1.15em;
  }
}
@media (min-width: 140em) {
  html {
    font-size: 1.2em;
  }
}
@media (min-width: 150em) {
  html {
    font-size: 1.25em;
  }
}